<template>
  <div>
  <b-row>
    <b-col cols="12">
      <company-fulfillments-list-component :show_initial="true" :show_periodic="false"/>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="12">
      <company-fulfillments-list-component :show_initial="false" :show_periodic="true"/>
    </b-col>
  </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import GoodTableBasic from './GoodTableBasic.vue'
import GoodTableRowGroup from './GoodTableRowGroup.vue'
import GoodTableColumnSearch from './GoodTableColumnSearch.vue'
import GoodTableAdvanceSearch from './GoodTableAdvanceSearch.vue'
import EmployeesListComponent from "@/views/table/vue-good-table/EmployeesListComponent";
import CompanyFulfillmentsListComponent from "@/views/table/vue-good-table/CompanyFulfillmentsListComponent";
import GoodTableSsr from './GoodTableSsr.vue'

export default {
  components: {
    CompanyFulfillmentsListComponent,
    BRow,
    BCol,

    GoodTableBasic,
    GoodTableRowGroup,
    GoodTableColumnSearch,
    GoodTableAdvanceSearch,
    EmployeesListComponent,
    GoodTableSsr,
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
